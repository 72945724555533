import { strToWad, wmul } from '@hailstonelabs/big-number-utils'
import { BigNumber, utils } from 'ethers'
import { ChangeEvent, SetStateAction, useEffect, useState } from 'react'
import { Asset } from '../../constants/contract/asset/Asset'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { Token } from '../../constants/contract/token/Token'
import { formatNumberUSLocale, replaceString, sliceDecimal } from '../../utils/numberFormat'
import { decimalAndInteger } from '../../utils/regex'
import { POOL_STATE } from '../PoolPage'
import Slider from '../Slider/Slider'
import TokenImage from '../TokenImage'

interface Props {
  selectedAsset: Asset
  token: Token
  balance: string | undefined
  handleChangeAmount: (value: BigNumber | null) => void
  setTokenAmount: (value: BigNumber | null) => void
  poolState: POOL_STATE
  amountTokenMapping: string
  tokenAmountForDisplay: string
  setTokenAmountForDisplay: React.Dispatch<SetStateAction<string>>
  decimals: number
}

const PoolInput = ({
  selectedAsset,
  token,
  balance,
  handleChangeAmount,
  setTokenAmount,
  poolState,
  amountTokenMapping,
  tokenAmountForDisplay,
  setTokenAmountForDisplay,
  decimals,
}: Props) => {
  const [sliderValue, setSliderValue] = useState<string>('0')

  const handleMax = () => {
    setSliderValue('100')
    if (poolState === POOL_STATE.DEPOSIT || poolState === POOL_STATE.WITHDRAW) {
      setTokenAmountForDisplay(balance || '0')
      setTokenAmount(utils.parseUnits(balance || '0', decimals))
    } else {
      setTokenAmountForDisplay(amountTokenMapping)
      setTokenAmount(utils.parseUnits(amountTokenMapping, decimals))
    }
  }

  const renderBalance = () => {
    switch (poolState) {
      case POOL_STATE.DEPOSIT:
        return 'Balance: ' + formatNumberUSLocale(balance || '0')
      case POOL_STATE.WITHDRAW:
        // Not used. WithdrawInput is used instead
        return ''
      case POOL_STATE.STAKE:
        return `Balance: ${formatNumberUSLocale(amountTokenMapping)} LP-${
          selectedAsset.displaySymbol
        }`
      case POOL_STATE.UNSTAKE:
        return `Staked: ${formatNumberUSLocale(amountTokenMapping)} LP-${
          selectedAsset.displaySymbol
        }`
      default:
        return ''
    }
  }

  const amountOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const replaceVal = replaceString(e.target.value, ',', '.')
      if (decimalAndInteger.test(replaceVal)) {
        setTokenAmountForDisplay(replaceVal)
        try {
          handleChangeAmount(utils.parseUnits(replaceVal, decimals))
        } catch (error) {
          const errInfo =
            '@handleChangeAmount\n' +
            `value: ${utils.parseUnits(replaceVal, decimals).toString()}\n`
          console.error(errInfo, error)
        }

        let value = 0
        switch (poolState) {
          case POOL_STATE.DEPOSIT:
            if (Number(balance) > 0) {
              value = (Number(replaceVal) / Number(balance)) * 100
            } else {
              value = 0
            }
            break
          case POOL_STATE.STAKE:
          case POOL_STATE.UNSTAKE:
            if (Number(amountTokenMapping) > 0) {
              value = (Number(replaceVal) / Number(amountTokenMapping)) * 100
            } else {
              value = 0
            }
            break
        }

        setSliderValue(sliceDecimal(value, 2))
      }
    } else {
      setTokenAmountForDisplay(e.target.value)
      handleChangeAmount(null)
      setSliderValue('0')
    }
  }

  const handleSliderOnChange = (val: string) => {
    setSliderValue(val)
    if (Number(val) === 100)
      handleMax() // set tokenamount tokenAmountDisplay to max balance when val slider go to 100
    else {
      try {
        const tokenAmountWad = wmul(strToWad(val), strToWad(amountTokenMapping)).div('100')

        setTokenAmountForDisplay(utils.formatEther(tokenAmountWad))
        setTokenAmount(
          utils.parseUnits((Number(val) / 100) * Number(amountTokenMapping) + '', decimals)
        )
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }

  useEffect(() => {
    if (!tokenAmountForDisplay) setSliderValue('0')
  }, [tokenAmountForDisplay])

  const isBetaLogoShown = selectedAsset.poolLabel === PoolLabels.CROSS_CHAIN

  return (
    <div className="w-full rounded-lg bg-wombatCream p-3 font-Work">
      <div className="flex items-center gap-2 pb-2">
        <div className="h-[34px]">
          <TokenImage tokenSymbol={token.symbol} width="34" height="34" />
        </div>
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center pl-1">
            <div className="select-none flex-row">
              <div className="font-semibold leading-5">
                {poolState === POOL_STATE.STAKE || poolState === POOL_STATE.UNSTAKE
                  ? `LP-${selectedAsset.displaySymbol}`
                  : selectedAsset.displaySymbol}
              </div>
              {(poolState === POOL_STATE.DEPOSIT || poolState === POOL_STATE.WITHDRAW) && (
                <div className="font-Work text-xs leading-3 text-wombatBrown1">{token.name}</div>
              )}
            </div>
            {isBetaLogoShown && (
              <div className="ml-2 mr-2 flex items-center bg-linearGradientApprove px-[5px] font-Quantico font-bold text-white">
                Beta
              </div>
            )}
          </div>
          <div className="abosolute right-4 top-6 font-Work text-xs font-normal text-wombatPurple">
            {renderBalance()}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between  rounded-md border-2 border-wombatPurple1 bg-white px-2 ">
        <input
          type="text"
          inputMode="numeric"
          placeholder="0.00"
          className="h-8 w-full rounded-lg bg-white font-Work text-base font-semibold text-wombatBrown1 focus:outline-none"
          value={tokenAmountForDisplay}
          onChange={amountOnchange}
        />
        <button
          className="button-hover-opacity px-1 font-Work font-semibold text-wombatPurple"
          onClick={handleMax}
        >
          MAX
        </button>
      </div>
      <Slider hasHeadValue={true} value={sliderValue} callBack={handleSliderOnChange} />
    </div>
  )
}

export default PoolInput
