import { ChainId as WormholeChainId } from '@certusone/wormhole-sdk'
import { ZERO_ADDRESS } from '../../common'
import { SupportedChainId, supportedChainIdToWormholeChainIdMap } from '../../web3/supportedChainId'
import { PoolLabels } from '../pool/PoolLabels'
import { FetchPriceMethod, Token, UniswapQuoterAddress } from './Token'
import { HardcodedTokenSymbol, SwapGroupSymbol, TokenSymbol, TokenSymbols } from './TokenSymbols'

const ETH_PYTH_ID = '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace'
const USDT_PYTH_ID = '0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b'
const USDC_PYTH_ID = '0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a'
const WOM_PYTH_ID = '0x43cddc3e1d0b17fec1cf2a230f46e9319887a037dcee56e053af44d8259fb042'
const WBTC_PYTH_ID = '0xc9d8b075a5c69303365ae23633d4e085199bf5c520a3b90fed1322a0342ffc33'
const WETH_PYTH_ID = '0x9d4294bbcd1174d6f2003ec365831e64cc31d9f6f15a2b85399db8d5000960f6'
const AUSD_PYTH_ID = '0xd9912df360b5b7f21a122f15bdd5e27f62ce5e72bd316c291f7c86620e07fb2a'

export type TokenMaps = {
  [id in TokenSymbol]?: Token
}
// TOKENS provide decimals and data for price fetching
/** New Pool Release: Adding token, reward prices and infos  */
/** This order affect defualt tokenB in swap */
export const TOKENS: {
  [id in SupportedChainId]: TokenMaps
} = {
  [SupportedChainId.BSC_TESTNET]: {
    [TokenSymbols.QUO]: new Token(
      TokenSymbols.QUO,
      'Quoll',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x458C742849d041723EfadD9a31153233de442B9b',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '0.02',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.RT1]: new Token(
      TokenSymbols.RT1,
      'Reward Token 1',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x9bbC325Eb7a7367bE610bCe614C91EF7F29c69dc',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '0', symbol: TokenSymbols.WBNB },
          pairAddress: '0x74db62eea4152b6b9669c94149430ca1554183da',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.RT2]: new Token(
      TokenSymbols.RT2,
      'Reward Token 2',
      8,
      SupportedChainId.BSC_TESTNET,
      '0x615F8656b763FF4A6a82B3cbBd54d392834df13F',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '0', symbol: TokenSymbols.WBNB },
          pairAddress: '0x74db62eea4152b6b9669c94149430ca1554183da',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.BUSD]: new Token(
      TokenSymbols.BUSD,
      'Binance USD',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x326335BA4e70cb838Ee55dEB18027A6570E5144d',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x06c532524fabd49cc142815502d785e4f34ec3bd035480efb770568d423f46c6',
        },
      },
      SwapGroupSymbol.USD,
      'BUSD',
      true
    ),
    [TokenSymbols.USDC]: new Token(
      TokenSymbols.USDC,
      'USD Coin',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x254dF1f8A8Fa9B7bFAd9e25bF912ea71484332cE',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x41f3625971ca2ed2263e78573fe5ce23e13d2558ed3f2e47ab0f84fb9e7ae722',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.USDT]: new Token(
      TokenSymbols.USDT,
      'Tether USD',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x6E847Cc3383525Ad33bEDd260139c1e097546B60',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x1fc18861232290221461220bd4e2acd1dcdfbc89c84092c93c18bdc7756c1588',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.DAI]: new Token(
      TokenSymbols.DAI,
      'Dai Stablecoin',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x735d905451c0B4ac4BBe5Ab323Cf5D6Ad7e3A030',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x87a67534df591d2dd5ec577ab3c75668a8e3d35e92e27bf29d9e2e52df8de412',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.vUSDC]: new Token(
      TokenSymbols.vUSDC,
      'Venus USDC',
      8,
      SupportedChainId.BSC_TESTNET,
      '0x9cc77B893d40861854fD90Abaf8414a5bD2bEcf8',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.USD,
      'vUSDC',
      true
    ),
    [TokenSymbols.HAY]: new Token(
      TokenSymbols.HAY,
      'HAY',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x97E0f48247EBad99456389a5C937Ca85975a7e8D',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.FRAX]: new Token(
      TokenSymbols.FRAX,
      'FRAX',
      18,
      SupportedChainId.BSC_TESTNET,
      '0xa5c67cD016df71f9CDCfd9e76A749a1DDca6209d',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '0', symbol: TokenSymbols.BUSD },
          pairAddress: '0x03a162385f9E98d57e58e6abCa8Bd54Dd51AF6ce',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.MIM]: new Token(
      TokenSymbols.MIM,
      'MIM',
      18,
      SupportedChainId.BSC_TESTNET,
      '0xC82930a91c6fc643608A3626D552AA303DF2eDC7',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.iUSD]: new Token(
      TokenSymbols.iUSD,
      'iZUMi Bond USD',
      18,
      SupportedChainId.BSC_TESTNET,
      '0xe07829c8B7F934e03C83B0dC1fd2cCC9b62036D8',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.CUSD]: new Token(
      TokenSymbols.CUSD,
      'Coin98 Dollar',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x27Ec2901fb369B5C7d34fCabD8f03833F0741ef8',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.axlUSDC]: new Token(
      TokenSymbols.axlUSDC,
      'Axelar Wrapped USDC',
      6,
      SupportedChainId.BSC_TESTNET,
      '0x9624f6868807A44fCe6c52c2b9Ca28E07c3fBb59',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.TUSD]: new Token(
      TokenSymbols.TUSD,
      'TrueUSD',
      18,
      SupportedChainId.BSC_TESTNET,
      '0xFE9AbD3dC0975f00e5C4ca6B148a992758F6A819',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.USDD]: new Token(
      TokenSymbols.USDD,
      'Decentralized USD',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x5cf1c3F9c0EaBCd0EFF825C0d5c8A8B16b11626a',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.WBNB]: new Token(
      TokenSymbols.WBNB,
      'BNB', // display as BNB
      18,
      SupportedChainId.BSC_TESTNET,
      '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0xecf553770d9b10965f8fb64771e93f5690a182edc32be4a3236e0caaa6e0581a',
        },
      },
      SwapGroupSymbol.BNB,
      'BNB' // display as BNB
    ),
    [TokenSymbols.BNBx]: new Token(
      TokenSymbols.BNBx,
      'Stader BNB',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x3ECB02c703C815e9cFFd8d9437B7A2F93638d7Cb',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          tokenSymbol: TokenSymbols.WBNB,
        },
      },
      SwapGroupSymbol.BNB
    ),
    [TokenSymbols.stkBNB]: new Token(
      TokenSymbols.stkBNB,
      'pSTAKE BNB',
      18,
      SupportedChainId.BSC_TESTNET,
      '0xF7CE8444b3b1c62e785a25343a8B4764198A81B8',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          tokenSymbol: TokenSymbols.WBNB,
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.ankrBNB]: new Token(
      TokenSymbols.ankrBNB,
      'Ankr BNB',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x46dE2FBAf41499f298457cD2d9288df4Eb1452Ab',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          tokenSymbol: TokenSymbols.WBNB,
        },
      },
      SwapGroupSymbol.UNAVAILABLE,
      'aBNBc'
    ),
    [TokenSymbols.qWOM]: new Token(
      TokenSymbols.qWOM,
      'Quoll Wom',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x28640e7a872f15C71CE02F31D95fe2941287d144',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.qWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.mWOM]: new Token(
      TokenSymbols.mWOM,
      'mWOM',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x19DBbA3C11f2f484c6BD0288834Edb0eFd5eD672',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.mWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.wmxWOM]: new Token(
      TokenSymbols.wmxWOM,
      'wmxWOM',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x39bbBc689E2Da5D777c57707f4577f7869C751aD',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.wmxWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.WOM]: new Token(
      TokenSymbols.WOM,
      'WOM',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x7BFC90abeEB4138e583bfC46aBC69De34c9ABb8B',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.WBNB },
          pairAddress: '0x63965ea189fbcd511defab64a2e13948dc9cb46b',
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.BOB]: new Token(
      TokenSymbols.BOB,
      'zkBob',
      18,
      SupportedChainId.BSC_TESTNET,
      '0x8C8A3B0874eAcf2913965BA79E81124f4542b399',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.WETH]: new Token(
      TokenSymbols.WETH,
      'Wrapped Ether',
      18,
      SupportedChainId.BSC_TESTNET,
      ZERO_ADDRESS,
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1500',
        },
      },
      SwapGroupSymbol.ETH
    ),
    [TokenSymbols.frxETH]: new Token(
      TokenSymbols.frxETH,
      'Frax Ether',
      18,
      SupportedChainId.BSC_TESTNET,
      ZERO_ADDRESS,
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1500',
        },
      },
      SwapGroupSymbol.ETH
    ),
    [TokenSymbols.BNBy]: new Token(
      TokenSymbols.BNBy,
      'BNBy',
      18,
      SupportedChainId.BSC_TESTNET,
      ZERO_ADDRESS,
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '0.01',
        },
      },
      SwapGroupSymbol.BNB
    ),
    [TokenSymbols.TENFI]: new Token(
      TokenSymbols.TENFI,
      'TEN Finance',
      18,
      SupportedChainId.BSC_TESTNET,
      ZERO_ADDRESS,
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '0.01',
        },
      },
      SwapGroupSymbol.UNAVAILABLE,
      'TEN'
    ),
    [TokenSymbols.sfrxETH]: new Token(
      TokenSymbols.sfrxETH,
      'Frax Staked Ether',
      18,
      SupportedChainId.BSC_TESTNET,
      ZERO_ADDRESS,
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'staked-frax-ether',
        },
      },
      SwapGroupSymbol.ETH
    ),
  },
  [SupportedChainId.BSC_MAINNET]: {
    [TokenSymbols.HZN]: new Token(
      TokenSymbols.HZN,
      'HZN',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xc0eff7749b125444953ef89682201fb8c6a917cd',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'horizon-protocol',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.zBNB]: new Token(
      TokenSymbols.zBNB,
      'zBNB',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x6DEdCEeE04795061478031b1DfB3c1ddCA80B204',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.zBNB,
          toTokenSymbol: TokenSymbols.WBNB,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.zUSD]: new Token(
      TokenSymbols.zUSD,
      'zUSD',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xF0186490B18CB74619816CfC7FeB51cdbe4ae7b9',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.zUSD,
          toTokenSymbol: TokenSymbols.USDC,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.slisBNB]: new Token(
      TokenSymbols.slisBNB,
      'slisBNB',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xb0b84d294e0c75a6abe60171b70edeb2efd14a1b',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.slisBNB,
          toTokenSymbol: TokenSymbols.WBNB,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.ankrETH]: new Token(
      TokenSymbols.ankrETH,
      'ankrETH',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xe05A08226c49b636ACf99c40Da8DC6aF83CE5bB3',
      /**@todo create new price fetch method for ETH-variant token  */
      { method: FetchPriceMethod.COINGECKO, payload: { id: 'ankreth' } },
      SwapGroupSymbol.ETH
    ),
    [TokenSymbols.wBETH]: new Token(
      TokenSymbols.wBETH,
      'wBETH',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xa2E3356610840701BDf5611a53974510Ae27E2e1',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          toTokenSymbol: TokenSymbols.ETH,
          poolLabel: PoolLabels.wBETH,
        },
      },
      SwapGroupSymbol.ETH
    ),
    [TokenSymbols.ankrBNB]: new Token(
      TokenSymbols.ankrBNB,
      'ankrBNB',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x52f24a5e03aee338da5fd9df68d2b6fae1178827',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: { id: 'ankr-staked-bnb' },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.MIM]: new Token(
      TokenSymbols.MIM,
      'MIM',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xfE19F0B51438fd612f6FD59C1dbB3eA319f433Ba',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '0', symbol: TokenSymbols.BUSD },
          pairAddress: '0x9911e98974d0BaDDE85bD5F4d1f93087Aa3Ec5fa',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols['USDT+']]: new Token(
      TokenSymbols['USDT+'],
      'USDT+',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x5335E87930b410b8C5BB4D43c3360ACa15ec0C8C',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.Overnight,
          toTokenSymbol: TokenSymbols.USDC,
        },
      },
      SwapGroupSymbol.USD,
      'USDT+'
    ),
    [TokenSymbols['USD+']]: new Token(
      TokenSymbols['USD+'],
      'USD+',
      6,
      SupportedChainId.BSC_MAINNET,
      '0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.Overnight,
          toTokenSymbol: TokenSymbols.USDC,
        },
      },
      SwapGroupSymbol.USD,
      'USD+'
    ),
    [TokenSymbols.SPELL]: new Token(
      TokenSymbols.SPELL,
      'Spell Token',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x9Fe28D11ce29E340B7124C493F59607cbAB9ce48',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x1dcf38b0206d27849b0fcb8d2df21aff4f95873cce223f49d7c1ea3c5145ec63',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.USDC]: new Token(
      TokenSymbols.USDC,
      'USD Coin',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDC',
      true
    ),
    [TokenSymbols.BUSD]: new Token(
      TokenSymbols.BUSD,
      'Binance USD',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'binance-peg-busd',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.TUSD]: new Token(
      TokenSymbols.TUSD,
      'TrueUSD',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x14016E85a25aeb13065688cAFB43044C2ef86784',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.BUSD },
          pairAddress: '0x2e28b9b74d6d99d4697e913b82b41ef1cac51c6c',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.FRAX]: new Token(
      TokenSymbols.FRAX,
      'FRAX',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40',
      /** FRAX is in Fraxswap: https://bscscan.com/address/0x14732123c443f8e815d5c64f3c7ecb63bceeab74#readContract */
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.WBNB },
          pairAddress: '0x14732123c443f8E815D5c64f3C7ecb63bCeEab74',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.FXS]: new Token(
      TokenSymbols.FXS,
      'FXS',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '0', symbol: TokenSymbols.WBNB },
          pairAddress: '0x14f1f1d54f06701a4be0e2d0f7992237c3ead1fa',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.QUO]: new Token(
      TokenSymbols.QUO,
      'Quoll',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x08b450e4a48C04CDF6DB2bD4cf24057f7B9563fF',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.BUSD },
          pairAddress: '0x12b9E2bA7729096C661C17D585E2C2a24d7daFC7',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.MGP]: new Token(
      TokenSymbols.MGP,
      'Magpie Token',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xD06716E1Ff2E492Cc5034c2E81805562dd3b45fa',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.BUSD },
          pairAddress: '0x76B0d10A4540B6743aa086EA31DC48E8AB691c4d',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.WMX]: new Token(
      TokenSymbols.WMX,
      '',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xa75d9ca2a0a1D547409D82e1B06618EC284A2CeD',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.BUSD },
          pairAddress: '0xe86eaAD81C32ffbb88B7ec9B325C8f75C8c9f1Ab',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.PSTAKE]: new Token(
      TokenSymbols.PSTAKE,
      '',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x4C882ec256823eE773B25b414d36F92ef58a7c0C',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.BUSD },
          pairAddress: '0x009C58e79779982eB53a9941F9F4a2269d093566',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.ANKR]: new Token(
      TokenSymbols.ANKR,
      'ANKR',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xf307910A4c7bbc79691fD374889b36d8531B08e3',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '0', symbol: TokenSymbols.WBNB },
          pairAddress: '0x540Ebc5c92839c300Cb64D8350811AEEE0C2b91D',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.SD]: new Token(
      TokenSymbols.SD,
      '',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x3BC5AC0dFdC871B365d159f728dd1B9A0B5481E8',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.BUSD },
          pairAddress: '0x867EB519b05d9C4798B2EdE0B11197274dfDFcC0',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.USDT]: new Token(
      TokenSymbols.USDT,
      'Tether USD',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x55d398326f99059fF775485246999027B3197955',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDT_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDT',
      true
    ),
    [TokenSymbols.DAI]: new Token(
      TokenSymbols.DAI,
      'Dai Stablecoin',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0xb0948a5e5313200c632b51bb5ca32f6de0d36e9950a942d19751e833f70dabfd',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.lisUSD]: new Token(
      TokenSymbols.lisUSD,
      'Lista USD',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.BUSD },
          pairAddress: '0x70c26e9805ec5Df3d4aB0b2a3dF86BBA2231B6c1',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.WBNB]: new Token(
      TokenSymbols.WBNB,
      'BNB', // display as BNB
      18,
      SupportedChainId.BSC_MAINNET,
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f',
        },
      },
      SwapGroupSymbol.USD,
      'BNB' // display as BNB
    ),
    [TokenSymbols.BNBx]: new Token(
      TokenSymbols.BNBx,
      'Stader BNB',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
      {
        method: FetchPriceMethod.BNBEXCHANGERATE,
        payload: null,
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.stkBNB]: new Token(
      TokenSymbols.stkBNB,
      'pSTAKE BNB',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16',
      {
        method: FetchPriceMethod.BNBEXCHANGERATE,
        payload: null,
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.WOM]: new Token(
      TokenSymbols.WOM,
      'WOM',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xad6742a35fb341a9cc6ad674738dd8da98b94fb1',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.BUSD },
          pairAddress: '0xe68d05418a8d7969d9ca6761ad46f449629d928c',
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.qWOM]: new Token(
      TokenSymbols.qWOM,
      'Quoll WOM',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x0fE34B8aaAf3f522A6088E278936D10F934c0b19',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.qWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.mWOM]: new Token(
      TokenSymbols.mWOM,
      'mWOM',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x027a9d301FB747cd972CFB29A63f3BDA551DFc5c',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.mWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.wmxWOM]: new Token(
      TokenSymbols.wmxWOM,
      'wmxWOM',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x0415023846Ff1C6016c4d9621de12b24B2402979',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.wmxWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    /** @todo the token name on mainnet is called wmxWom but not wmxWOM */
    [TokenSymbols.wmxWom]: new Token(
      TokenSymbols.wmxWom,
      'wmxWOM',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x0415023846Ff1C6016c4d9621de12b24B2402979',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.wmxWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.iUSD]: new Token(
      TokenSymbols.iUSD,
      'iZUMi Bond USD',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          toTokenSymbol: TokenSymbols.BUSD,
          poolLabel: PoolLabels.iUSD,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.CUSD]: new Token(
      TokenSymbols.CUSD,
      'Coin98 Dollar',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xFa4BA88Cf97e282c505BEa095297786c16070129',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          toTokenSymbol: TokenSymbols.BUSD,
          poolLabel: PoolLabels.CUSD,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.axlUSDC]: new Token(
      TokenSymbols.axlUSDC,
      'Axelar Wrapped USDC',
      6,
      SupportedChainId.BSC_MAINNET,
      '0x4268B8F0B87b6Eae5d897996E6b845ddbD99Adf3',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          toTokenSymbol: TokenSymbols.BUSD,
          poolLabel: PoolLabels.axlUSDC,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.USDD]: new Token(
      TokenSymbols.USDD,
      'Decentralized USD',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xd17479997F34dd9156Deef8F95A52D81D265be9c',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.BUSD },
          pairAddress: '0x004A9CE83614551271BcBd48E5fA3B8648Dbe312',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.BOB]: new Token(
      TokenSymbols.BOB,
      'zkBob',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.WBNB },
          pairAddress: '0xFD12fEBf4Db8c88dE14E0b9D64F09146B681F190',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.ETH]: new Token(
      TokenSymbols.ETH,
      'Binance-peg Ethereum Token',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          toToken: { reserveIndex: '1', symbol: TokenSymbols.USDC },
          pairAddress: '0xea26b78255df2bbc31c1ebf60010d78670185bd0',
        },
      },
      SwapGroupSymbol.ETH
    ),
    [TokenSymbols.WETH]: new Token(
      TokenSymbols.WETH,
      'Wrapped Ether',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x4DB5a66E937A9F4473fA95b1cAF1d1E1D62E29EA',
      {
        method: FetchPriceMethod.DEX,
        payload: {
          /**temporary using ETH price for WETH price */
          toToken: { reserveIndex: '1', symbol: TokenSymbols.WBNB },
          pairAddress: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.frxETH]: new Token(
      TokenSymbols.frxETH,
      'Frax Ether',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x64048A7eEcF3a2F1BA9e144aAc3D7dB6e58F555e',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          toTokenSymbol: TokenSymbols.ETH,
          poolLabel: PoolLabels.frxETH,
        },
      },
      SwapGroupSymbol.ETH
    ),
    [TokenSymbols.sfrxETH]: new Token(
      TokenSymbols.sfrxETH,
      'Frax Staked Ether',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x3Cd55356433C89E50DC51aB07EE0fa0A95623D53',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          toTokenSymbol: TokenSymbols.ETH,
          poolLabel: PoolLabels.frxETH,
        },
      },
      SwapGroupSymbol.ETH
    ),
    [TokenSymbols.BNBy]: new Token(
      TokenSymbols.BNBy,
      'BNBy',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x6764506be2a755c18f4c70bde4e63f26f9f62810',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          toTokenSymbol: TokenSymbols.WBNB,
          poolLabel: PoolLabels.BNBy,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.TENFI]: new Token(
      TokenSymbols.TENFI,
      'TEN Finance',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xd15c444f1199ae72795eba15e8c1db44e47abf62',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'ten',
        },
      },
      SwapGroupSymbol.UNAVAILABLE,
      'TEN'
    ),
    [TokenSymbols.rBNB]: new Token(
      TokenSymbols.rBNB,
      'rBNB',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xF027E525D491ef6ffCC478555FBb3CFabB3406a6',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.rBNB,
          toTokenSymbol: TokenSymbols.WBNB,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.FIS]: new Token(
      TokenSymbols.FIS,
      'stafi',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xF4bafAEAE73a4A7C8b6479970075e91e641fB1FC',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'stafi',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.USDS]: new Token(
      TokenSymbols.USDS,
      'USDS',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x0c6Ed1E73BA73B8441868538E210ebD5DD240FA0',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.USDS,
          toTokenSymbol: TokenSymbols.USDT,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.SABLE]: new Token(
      TokenSymbols.SABLE,
      'SABLE',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x1ee098cbaf1f846d5df1993f7e2d10afb35a878d',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'sable',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.USDV]: new Token(
      TokenSymbols.USDV,
      'USDV',
      6,
      SupportedChainId.BSC_MAINNET,
      '0x323665443CEf804A3b5206103304BD4872EA4253',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.USDV,
          toTokenSymbol: TokenSymbols.USDT,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.LINA]: new Token(
      TokenSymbols.LINA,
      'LINA',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x762539b45a1dcce3d36d080f74d1aed37844b878',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'linear',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.lUSD]: new Token(
      TokenSymbols.lUSD,
      'lUSD',
      18,
      SupportedChainId.BSC_MAINNET,
      '0xD89336Eac00e689D218C46Cdd854585A09f432b3',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.lUSD,
          toTokenSymbol: TokenSymbols.USDT,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.BTCB]: new Token(
      TokenSymbols.BTCB,
      'BTCB',
      18,
      SupportedChainId.BSC_MAINNET,
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'binance-bitcoin',
        },
      },
      SwapGroupSymbol.USD
    ),
  },
  [SupportedChainId.ARBITRUM_ONE]: {
    [TokenSymbols.ANKR]: new Token(
      TokenSymbols.ANKR,
      'ANKR',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x46F74778b265Df3a15EC9695CCd2fD3869ca848c',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x89a58e1cab821118133d6831f5018fba5b354afb78b2d18f575b3cbf69a4f652',
        },
      },
      SwapGroupSymbol.UNAVAILABLE,
      'multiANKR'
    ),
    [HardcodedTokenSymbol.newANKR]: new Token(
      HardcodedTokenSymbol.newANKR,
      'ANKR',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xAeAeeD23478c3a4b798e4ed40D8B7F41366Ae861',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x89a58e1cab821118133d6831f5018fba5b354afb78b2d18f575b3cbf69a4f652',
        },
      },
      SwapGroupSymbol.UNAVAILABLE,
      'ANKR'
    ),
    [TokenSymbols.ankrETH]: new Token(
      TokenSymbols.ankrETH,
      'ankrETH',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xe05A08226c49b636ACf99c40Da8DC6aF83CE5bB3',
      /**@todo create new price fetch method for ETH-variant token  */
      { method: FetchPriceMethod.COINGECKO, payload: { id: 'ankreth' } },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.JONES]: new Token(
      TokenSymbols.JONES,
      'Jones DAO',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x10393c20975cF177a3513071bC110f7962CD67da',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'jones-dao',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.jUSDC]: new Token(
      TokenSymbols.jUSDC,
      'Jones USDC',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xe66998533a1992ecE9eA99cDf47686F4fc8458E0',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'jones-usdc',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols['USDC.e']]: new Token(
      TokenSymbols['USDC.e'],
      'USD Coin',
      6,
      SupportedChainId.ARBITRUM_ONE,
      '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.USDC]: new Token(
      TokenSymbols.USDC,
      'USD Coin',
      6,
      SupportedChainId.ARBITRUM_ONE,
      '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDC',
      true
    ),
    [TokenSymbols.USDT]: new Token(
      TokenSymbols.USDT,
      'Tether USD',
      6,
      SupportedChainId.ARBITRUM_ONE,
      '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDT_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDT',
      true
    ),
    [TokenSymbols.DAI]: new Token(
      TokenSymbols.DAI,
      'Dai Stablecoin',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0xb0948a5e5313200c632b51bb5ca32f6de0d36e9950a942d19751e833f70dabfd',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.WOM]: new Token(
      TokenSymbols.WOM,
      'WOM',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x7b5eb3940021ec0e8e463d5dbb4b7b09a89ddf96',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'wombat-exchange',
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols['USD+']]: new Token(
      TokenSymbols['USD+'],
      'USD+',
      6,
      SupportedChainId.ARBITRUM_ONE,
      '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.Overnight,
          toTokenSymbol: TokenSymbols['USDC.e'],
        },
      },
      SwapGroupSymbol.USD,
      'USD+'
    ),
    [TokenSymbols['DAI+']]: new Token(
      TokenSymbols['DAI+'],
      'DAI+',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xeb8e93a0c7504bffd8a8ffa56cd754c63aaebfe8',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.Overnight,
          toTokenSymbol: TokenSymbols['USDC.e'],
        },
      },
      SwapGroupSymbol.USD,
      'DAI+'
    ),
    [TokenSymbols.MIM]: new Token(
      TokenSymbols.MIM,
      'MIM',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'magic-internet-money',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.BOB]: new Token(
      TokenSymbols.BOB,
      'zkBob',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
      {
        method: FetchPriceMethod.UNISWAPV3,
        payload: {
          poolAddress: '0xdbBc93072295362D38B63ACCd447D9c0B36a1678',
          quoterAddress: UniswapQuoterAddress.v3,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.qWOM]: new Token(
      TokenSymbols.qWOM,
      'Quoll WOM',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x388D157F0BFdc1d30357AF63a8be10BfF8474f4e',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.qWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.mWOM]: new Token(
      TokenSymbols.mWOM,
      'mWOM',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x509FD25EE2AC7833a017f17Ee8A6Fb4aAf947876',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.mWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.wmxWOM]: new Token(
      TokenSymbols.wmxWOM,
      'wmxWOM',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xEfF2B1353Cdcaa2C3279C2bfdE72120c7FfB5E24',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.wmxWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    /** @todo the token name on mainnet is called wmxWom but not wmxWOM */
    [TokenSymbols.wmxWom]: new Token(
      TokenSymbols.wmxWom,
      'wmxWOM',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xEfF2B1353Cdcaa2C3279C2bfdE72120c7FfB5E24',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.wmxWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.WETH]: new Token(
      TokenSymbols.WETH,
      'ETH',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: ETH_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'ETH'
    ),
    [TokenSymbols.frxETH]: new Token(
      TokenSymbols.frxETH,
      'Frax Ether',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x178412e79c25968a32e89b11f63B33F733770c2A',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'frax-ether',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.MAI]: new Token(
      TokenSymbols.MAI,
      'MIMATIC',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },

      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.QUO]: new Token(
      TokenSymbols.QUO,
      'Quoll',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xf00D8790A76ee5A5Dbc10eaCac39151aa2af0331',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'quo',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.MGP]: new Token(
      TokenSymbols.MGP,
      'Magpie',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xa61F74247455A40b01b0559ff6274441FAfa22A3',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'magpie',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.WMX]: new Token(
      TokenSymbols.WMX,
      'Wombex',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x5190F06EaceFA2C552dc6BD5e763b81C73293293',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'wombex',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.FXS]: new Token(
      TokenSymbols.FXS,
      'Frax Share',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x9d2F299715D94d8A7E6F5eaa8E654E8c74a988A7',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x735f591e4fed988cd38df74d8fcedecf2fe8d9111664e0fd500db9aa78b316b1',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.SPELL]: new Token(
      TokenSymbols.SPELL,
      'Spell',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'spell-token',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.QI]: new Token(
      TokenSymbols.QI,
      'Qi Dao',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xB9C8F0d3254007eE4b98970b94544e473Cd610EC',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'qi-dao',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.FRAX]: new Token(
      TokenSymbols.FRAX,
      'FRAX',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'frax',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.sfrxETH]: new Token(
      TokenSymbols.sfrxETH,
      'Frax Staked Ether',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x95aB45875cFFdba1E5f451B950bC2E42c0053f39',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'staked-frax-ether',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.wstETH]: new Token(
      TokenSymbols.wstETH,
      'Lido wstETH',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x5979D7b546E38E414F7E9822514be443A4800529',
      /**@todo create new price fetch method for ETH-variant token  */
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x6df640f3b8963d8f8358f791f352b8364513f6ab1cca5ed3f1f7b5448980e784',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.ARB]: new Token(
      TokenSymbols.ARB,
      'Arbitrum',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x912CE59144191C1204E64559FE8253a0e49E6548',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.fUSDC]: new Token(
      TokenSymbols.fUSDC,
      'Fluid USDC',
      6,
      SupportedChainId.ARBITRUM_ONE,
      '0x4cfa50b7ce747e2d61724fcac57f24b748ff2b2a',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.fUSDC,
          toTokenSymbol: TokenSymbols['USDC.e'],
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.PENDLE]: new Token(
      TokenSymbols.PENDLE,
      'PENDLE',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x0c880f6761F1af8d9Aa9C466984b80DAb9a8c9e8',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x9a4df90b25497f66b1afb012467e316e801ca3d839456db028892fe8c70c8016',
        },
      },
      SwapGroupSymbol.mPendle
    ),
    [TokenSymbols.mPendle]: new Token(
      TokenSymbols.mPendle,
      'mPendle',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xB688BA096b7Bb75d7841e47163Cd12D18B36A5bF',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.mPendle,
          toTokenSymbol: TokenSymbols.PENDLE,
        },
      },
      SwapGroupSymbol.mPendle
    ),
    [TokenSymbols.PNP]: new Token(
      TokenSymbols.PNP,
      'PNP',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0x2ac2b254bc18cd4999f64773a966e4f4869c34ee',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'penpie',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.USDV]: new Token(
      TokenSymbols.USDV,
      'USDV',
      6,
      SupportedChainId.ARBITRUM_ONE,
      '0x323665443CEf804A3b5206103304BD4872EA4253',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.USDV,
          toTokenSymbol: TokenSymbols.USDT,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.sFRAX]: new Token(
      TokenSymbols.sFRAX,
      'sFRAX',
      18,
      SupportedChainId.ARBITRUM_ONE,
      '0xe3b3FE7bcA19cA77Ad877A5Bebab186bEcfAD906',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'frax',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.WBTC]: new Token(
      TokenSymbols.WBTC,
      'WBTC',
      8,
      SupportedChainId.ARBITRUM_ONE,
      '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WBTC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD
    ),
  },
  [SupportedChainId.FUJI]: {
    [HardcodedTokenSymbol.AVAX]: new Token(
      HardcodedTokenSymbol.AVAX,
      'AVAX',
      18,
      SupportedChainId.FUJI,
      ZERO_ADDRESS,
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.UNAVAILABLE,
      'AVAX',
      false
    ),
    [TokenSymbols.BUSD]: new Token(
      TokenSymbols.BUSD,
      'BUSD',
      18,
      SupportedChainId.FUJI,
      '0x921ee0bdBB71065DCC15d201Cc99F63d71224b87',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.USD,
      'BUSD',
      true
    ),
    [TokenSymbols.vUSDC]: new Token(
      TokenSymbols.vUSDC,
      'vUSDC',
      8,
      SupportedChainId.FUJI,
      '0x8Cfa834ebBE803294020b08c521aA4637cB3dC1A',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '1',
        },
      },
      SwapGroupSymbol.USD,
      'vUSDC',
      true
    ),
  },
  [SupportedChainId.ETHEREUM_MAINNET]: {
    [TokenSymbols.USDC]: new Token(
      TokenSymbols.USDC,
      'USD Coin',
      6,
      SupportedChainId.ETHEREUM_MAINNET,
      '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDC',
      true
    ),
    [TokenSymbols.USDT]: new Token(
      TokenSymbols.USDT,
      'Tether USD',
      6,
      SupportedChainId.ETHEREUM_MAINNET,
      '0xdac17f958d2ee523a2206206994597c13d831ec7',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDT_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDT',
      true
    ),
    [TokenSymbols.WOM]: new Token(
      TokenSymbols.WOM,
      'WOM',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0xc0B314a8c08637685Fc3daFC477b92028c540CFB',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WOM_PYTH_ID,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [HardcodedTokenSymbol.rETH]: new Token(
      HardcodedTokenSymbol.rETH,
      'rETH',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0xae78736cd615f374d3085123a210448e74fc6393',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0xa0255134973f4fdf2f8f7808354274a3b1ebc6ee438be898d045e8b56ba1fe13',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [HardcodedTokenSymbol.LBR]: new Token(
      HardcodedTokenSymbol.LBR,
      'LBR',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0xf1182229b71e79e504b1d2bf076c15a277311e05',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'lybra-finance',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.ETHx]: new Token(
      TokenSymbols.ETHx,
      'Stader ETHx',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0xa35b1b31ce002fbf2058d22f30f95d405200a15b',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'stader-ethx',
        },
      },
      SwapGroupSymbol.USD
    ),
    [HardcodedTokenSymbol.eUSD]: new Token(
      HardcodedTokenSymbol.eUSD,
      'eUSD',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x97de57ec338ab5d51557da3434828c5dbfada371',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'eusd-27a558b0-8b5b-4225-a614-63539da936f4',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.frxETH]: new Token(
      TokenSymbols.frxETH,
      'Frax Ether',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x5e8422345238f34275888049021821e8e08caa1f',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'staked-frax-ether',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.sfrxETH]: new Token(
      TokenSymbols.sfrxETH,
      'Frax Staked Ether',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0xac3e018457b222d93114458476f3e3416abbe38f',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'staked-frax-ether',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.FRAX]: new Token(
      TokenSymbols.FRAX,
      'FRAX',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x853d955acef822db058eb8505911ed77f175b99e',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'frax',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.ANKR]: new Token(
      TokenSymbols.ANKR,
      'ANKR',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x8290333cef9e6d528dd5618fb97a76f268f3edd4',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x89a58e1cab821118133d6831f5018fba5b354afb78b2d18f575b3cbf69a4f652',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.wstETH]: new Token(
      TokenSymbols.wstETH,
      'Lido wstETH',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x6df640f3b8963d8f8358f791f352b8364513f6ab1cca5ed3f1f7b5448980e784',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.WETH]: new Token(
      TokenSymbols.WETH,
      'ETH',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'weth',
        },
      },
      SwapGroupSymbol.USD,
      'ETH'
    ),
    [TokenSymbols.EURA]: new Token(
      TokenSymbols.EURA,
      'EURA',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x1a7e4e63778B4f12a199C062f3eFdD288afCBce8',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'ageur',
        },
      },
      SwapGroupSymbol.EUR
    ),
    [TokenSymbols.EURe]: new Token(
      TokenSymbols.EURe,
      'Monerium EUR emoney',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x3231Cb76718CDeF2155FC47b5286d82e6eDA273f',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'monerium-eur-money',
        },
      },
      SwapGroupSymbol.EUR
    ),
    [TokenSymbols.ANGLE]: new Token(
      TokenSymbols.ANGLE,
      'ANGLE',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x31429d1856ad1377a8a0079410b297e1a9e214c2',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'angle-protocol',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.FXS]: new Token(
      TokenSymbols.FXS,
      'Frax Share',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x735f591e4fed988cd38df74d8fcedecf2fe8d9111664e0fd500db9aa78b316b1',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.SD]: new Token(
      TokenSymbols.SD,
      '',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x30D20208d987713f46DFD34EF128Bb16C404D10f',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'stader',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.mWOM]: new Token(
      TokenSymbols.mWOM,
      'mWOM',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0xca9Fb45f6600ee6e47E73666A6B7Dd407d131F96',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.mWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    /** @todo the token name on mainnet is called wmxWom but not wmxWOM */
    [TokenSymbols.wmxWom]: new Token(
      TokenSymbols.wmxWom,
      'wmxWOM',
      18,
      SupportedChainId.ETHEREUM_MAINNET,
      '0xEfF2B1353Cdcaa2C3279C2bfdE72120c7FfB5E24',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.wmxWOM,
          toTokenSymbol: TokenSymbols.WOM,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.USDV]: new Token(
      TokenSymbols.USDV,
      'USDV',
      6,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x0E573Ce2736Dd9637A0b21058352e1667925C7a8',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.USDV,
          toTokenSymbol: TokenSymbols.USDT,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.WBTC]: new Token(
      TokenSymbols.WBTC,
      'WBTC',
      8,
      SupportedChainId.ETHEREUM_MAINNET,
      '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WBTC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD
    ),
  },
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: {
    [TokenSymbols.USDC]: new Token(
      TokenSymbols.USDC,
      'USD Coin',
      18,
      SupportedChainId.SCROLL_SEPOLIA_TESTNET,
      '0x55bD28C34d5bDDe6A41F8565A569ED6281ba7C46',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDC'
    ),
    [TokenSymbols.USDT]: new Token(
      TokenSymbols.USDT,
      'Tether USD',
      6,
      SupportedChainId.SCROLL_SEPOLIA_TESTNET,
      '0x9f9fF16f55408DdDeD86E8F9c7aCf3762F21bEAA',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDT_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDT'
    ),
    [TokenSymbols.WOM]: new Token(
      TokenSymbols.WOM,
      'WOM',
      18,
      SupportedChainId.SCROLL_SEPOLIA_TESTNET,
      '0x78895E0d97bd723e9921c4d480DDDA84cFDBB82D',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WOM_PYTH_ID,
        },
      },
      SwapGroupSymbol.WOM
    ),
  },
  [SupportedChainId.SCROLL_MAINNET]: {
    [TokenSymbols.USDC]: new Token(
      TokenSymbols.USDC,
      'USD Coin',
      6,
      SupportedChainId.SCROLL_MAINNET,
      '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDC',
      true
    ),
    [TokenSymbols.USDT]: new Token(
      TokenSymbols.USDT,
      'Tether USD',
      6,
      SupportedChainId.SCROLL_MAINNET,
      '0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDT_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDT',
      true
    ),
    [TokenSymbols.WOM]: new Token(
      TokenSymbols.WOM,
      'WOM',
      18,
      SupportedChainId.SCROLL_MAINNET,
      '0x1a7aD8A6171A1EA84DD1E6d649cbd616189660D9',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WOM_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'WOM',
      true
    ),
    [TokenSymbols.ETH]: new Token(
      TokenSymbols.ETH,
      'ETH',
      18,
      SupportedChainId.SCROLL_MAINNET,
      '0x0000000000000000000000000000000000000000',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: ETH_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'ETH',
      false
    ),
    [TokenSymbols.WETH]: new Token(
      TokenSymbols.WETH,
      'ETH',
      18,
      SupportedChainId.SCROLL_MAINNET,
      '0x5300000000000000000000000000000000000004',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WETH_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'ETH'
    ),
    [TokenSymbols.WBTC]: new Token(
      TokenSymbols.WBTC,
      'WBTC',
      8,
      SupportedChainId.SCROLL_MAINNET,
      '0x3C1BCa5a656e69edCD0D4E36BEbb3FcDAcA60Cf1',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WBTC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD
    ),
  },
  [SupportedChainId.SKALE_TESTNET]: {
    [TokenSymbols.USDC]: new Token(
      TokenSymbols.USDC,
      'USD Coin',
      18,
      SupportedChainId.SKALE_TESTNET,
      '0xDa01302C86ECcd5bc94c1086777acF3c3Af7EF63',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDC'
    ),
    [TokenSymbols.USDT]: new Token(
      TokenSymbols.USDT,
      'Tether USD',
      6,
      SupportedChainId.SKALE_TESTNET,
      '0x6ad9C3C1670CEE303309bC82e7a5754dCb831205',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDT_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDT'
    ),
    [TokenSymbols.WOM]: new Token(
      TokenSymbols.WOM,
      'WOM',
      18,
      SupportedChainId.SKALE_TESTNET,
      '0x1BfA065E0f6F544b143a18e1BEb163d46b4378Aa',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WOM_PYTH_ID,
        },
      },
      SwapGroupSymbol.WOM
    ),
  },
  [SupportedChainId.OP_MAINNET]: {
    [TokenSymbols.WETH]: new Token(
      TokenSymbols.WETH,
      'ETH',
      18,
      SupportedChainId.OP_MAINNET,
      '0x4200000000000000000000000000000000000006',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: ETH_PYTH_ID,
        },
      },
      SwapGroupSymbol.ETH,
      'ETH'
    ),
    [TokenSymbols['USDC.e']]: new Token(
      TokenSymbols['USDC.e'],
      'USD Coin',
      6,
      SupportedChainId.OP_MAINNET,
      '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'usd-coin',
        },
      },
      SwapGroupSymbol.USD,
      'USDC.e',
      true
    ),
    [TokenSymbols.USDC]: new Token(
      TokenSymbols.USDC,
      'USD Coin',
      6,
      SupportedChainId.OP_MAINNET,
      '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDC',
      true
    ),
    [TokenSymbols.USDT]: new Token(
      TokenSymbols.USDT,
      'Tether USD',
      6,
      SupportedChainId.OP_MAINNET,
      '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDT_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDT',
      true
    ),
    [TokenSymbols.WOM]: new Token(
      TokenSymbols.WOM,
      'WOM',
      18,
      SupportedChainId.OP_MAINNET,
      '0xd2612b256f6f76fea8c6fbca0bf3166d0d13a668',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WOM_PYTH_ID,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.FRAX]: new Token(
      TokenSymbols.FRAX,
      'FRAX',
      18,
      SupportedChainId.OP_MAINNET,
      '0x2E3D870790dC77A83DD1d18184Acc7439A53f475',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'frax',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.sfrxETH]: new Token(
      TokenSymbols.sfrxETH,
      'Frax Staked Ether',
      18,
      SupportedChainId.OP_MAINNET,
      '0x484c2D6e3cDd945a8B2DF735e079178C1036578c',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'staked-frax-ether',
        },
      },
      SwapGroupSymbol.ETH
    ),
    [TokenSymbols.frxETH]: new Token(
      TokenSymbols.frxETH,
      'Frax Ether',
      18,
      SupportedChainId.OP_MAINNET,
      '0x6806411765Af15Bddd26f8f544A34cC40cb9838B',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'frax-ether',
        },
      },
      SwapGroupSymbol.ETH
    ),
    [TokenSymbols.USDV]: new Token(
      TokenSymbols.USDV,
      'USDV',
      6,
      SupportedChainId.OP_MAINNET,
      '0x323665443CEf804A3b5206103304BD4872EA4253',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.USDV,
          toTokenSymbol: TokenSymbols.USDT,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.DOLA]: new Token(
      TokenSymbols.DOLA,
      'DOLA',
      18,
      SupportedChainId.OP_MAINNET,
      '0x8aE125E8653821E851F12A49F7765db9a9ce7384',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'dola-usd',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.FXS]: new Token(
      TokenSymbols.FXS,
      'FXS',
      18,
      SupportedChainId.OP_MAINNET,
      '0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x735f591e4fed988cd38df74d8fcedecf2fe8d9111664e0fd500db9aa78b316b1',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.OP]: new Token(
      TokenSymbols.OP,
      'OP',
      18,
      SupportedChainId.OP_MAINNET,
      '0x4200000000000000000000000000000000000042',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: '0x385f64d993f7b77d8182ed5003d97c60aa3361f3cecfe711544d2d59165e9bdf',
        },
      },
      SwapGroupSymbol.UNAVAILABLE,
      'OP'
    ),
  },
  [SupportedChainId.AVAX]: {
    [TokenSymbols.WAVAX]: new Token(
      TokenSymbols.WAVAX,
      'Avalanche',
      18,
      SupportedChainId.AVAX,
      '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'wrapped-avax',
        },
      },
      SwapGroupSymbol.USD,
      'AVAX'
    ),
    [TokenSymbols.sAVAX]: new Token(
      TokenSymbols.sAVAX,
      'Staked AVAX',
      18,
      SupportedChainId.AVAX,
      '0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'benqi-liquid-staked-avax',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.QI]: new Token(
      TokenSymbols.QI,
      'BENQI',
      18,
      SupportedChainId.AVAX,
      '0x8729438eb15e2c8b576fcc6aecda6a148776c0f5',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'benqi',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.USDC]: new Token(
      TokenSymbols.USDC,
      'USD Coin',
      6,
      SupportedChainId.AVAX,
      '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDC',
      true
    ),
    /** USDT's symbol is USDt on avalanche */
    [TokenSymbols.USDT]: new Token(
      TokenSymbols.USDT,
      'Tether USD',
      6,
      SupportedChainId.AVAX,
      '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDT_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDT',
      true
    ),
    [TokenSymbols.WOM]: new Token(
      TokenSymbols.WOM,
      'WOM',
      18,
      SupportedChainId.AVAX,
      '0xa15E4544D141aa98C4581a1EA10Eb9048c3b3382',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WOM_PYTH_ID,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.USDV]: new Token(
      TokenSymbols.USDV,
      'USDV',
      6,
      SupportedChainId.AVAX,
      '0x323665443CEf804A3b5206103304BD4872EA4253',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.USDV,
          toTokenSymbol: TokenSymbols.USDT,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.ggAVAX]: new Token(
      TokenSymbols.ggAVAX,
      'ggAVAX',
      18,
      SupportedChainId.AVAX,
      '0xA25EaF2906FA1a3a13EdAc9B9657108Af7B703e3',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'gogopool-ggavax',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.GGP]: new Token(
      TokenSymbols.GGP,
      'GGP',
      18,
      SupportedChainId.AVAX,
      '0x69260b9483f9871ca57f81a90d91e2f96c2cd11d',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'gogopool',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols['BTC.b']]: new Token(
      TokenSymbols['BTC.b'],
      'BTC.b',
      8,
      SupportedChainId.AVAX,
      '0x152b9d0FdC40C096757F570A51E494bd4b943E50',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'bitcoin-avalanche-bridged-btc-b',
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.UXDA]: new Token(
      TokenSymbols.UXDA,
      'UXDA',
      18,
      SupportedChainId.AVAX,
      '0xc8e9053d354976ccd78f58fdbc7df2b0a9a127e6',
      {
        method: FetchPriceMethod.WOMBAT,
        payload: {
          poolLabel: PoolLabels.AXON_STABLE,
          toTokenSymbol: TokenSymbols.USDC,
        },
      },
      SwapGroupSymbol.USD
    ),
    [TokenSymbols.xflow]: new Token(
      TokenSymbols.xflow,
      'xflow',
      18,
      SupportedChainId.AVAX,
      '0x471e60Bbedb5588De8e47F3FD10E00Cb6a0C4e90',
      {
        method: FetchPriceMethod.HARDCODE,
        payload: {
          value: '0.03',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
    [TokenSymbols.AUSD]: new Token(
      TokenSymbols.AUSD,
      'AUSD',
      6,
      SupportedChainId.AVAX,
      '0x00000000eFE302BEAA2b3e6e1b18d08D69a9012a',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: AUSD_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD
    ),
  },
  [SupportedChainId.POLYGON_MAINNET]: {
    [TokenSymbols.USDC]: new Token(
      TokenSymbols.USDC,
      'USD Coin',
      6,
      SupportedChainId.POLYGON_MAINNET,
      '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDC'
    ),
    [TokenSymbols.USDT]: new Token(
      TokenSymbols.USDT,
      'Tether USD',
      6,
      SupportedChainId.POLYGON_MAINNET,
      '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDT_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDT'
    ),
    [TokenSymbols.WOM]: new Token(
      TokenSymbols.WOM,
      'WOM',
      18,
      SupportedChainId.POLYGON_MAINNET,
      '0x77749d37A87BFf80060c00152B213F61341A6de5',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WOM_PYTH_ID,
        },
      },
      SwapGroupSymbol.WOM
    ),
  },
  [SupportedChainId.BASE]: {
    [TokenSymbols.USDC]: new Token(
      TokenSymbols.USDC,
      'USD Coin',
      6,
      SupportedChainId.BASE,
      '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: USDC_PYTH_ID,
        },
      },
      SwapGroupSymbol.USD,
      'USDC',
      true
    ),
    [TokenSymbols.USDbC]: new Token(
      TokenSymbols.USDbC,
      'Bridged USD Coin',
      6,
      SupportedChainId.BASE,
      '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'bridged-usd-coin-base',
        },
      },
      SwapGroupSymbol.USD,
      'USDbC',
      true
    ),
    [TokenSymbols.WOM]: new Token(
      TokenSymbols.WOM,
      'WOM',
      18,
      SupportedChainId.BASE,
      '0xD9541B08B375D58ae104EC247d7443D2D7235D64',
      {
        method: FetchPriceMethod.PYTH,
        payload: {
          pythPriceFeedId: WOM_PYTH_ID,
        },
      },
      SwapGroupSymbol.WOM
    ),
    [TokenSymbols.USDS]: new Token(
      TokenSymbols.USDS,
      'Sable USD Coin',
      18,
      SupportedChainId.BASE,
      '0xecf3e9B8ccb6F4A6EFD68058FD706561c1727031',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'sable-coin',
        },
      },
      SwapGroupSymbol.USD,
      'USDS'
    ),
    [TokenSymbols.SABLE]: new Token(
      TokenSymbols.SABLE,
      'SABLE',
      18,
      SupportedChainId.BASE,
      '0xE5393cdBC4Fe7B62F1d76A990Ca7Da17ad718313',
      {
        method: FetchPriceMethod.COINGECKO,
        payload: {
          id: 'sable',
        },
      },
      SwapGroupSymbol.UNAVAILABLE
    ),
  },
  /** @todo uncomment it when needed */
  // [SupportedChainId.ZKSYNC_MAINNET]: {
  //   [TokenSymbols.USDC]: new Token(
  //     TokenSymbols.USDC,
  //     'USD Coin',
  //     18,
  //     SupportedChainId.ZKSYNC_MAINNET,
  //     '0x',
  //     {
  //       method: FetchPriceMethod.COINGECKO,
  //       payload: {
  //         id: 'usd-coin',
  //       },
  //     },
  //     SwapGroupSymbol.USD,
  //     'USDC'
  //   ),
  //   [TokenSymbols.USDT]: new Token(
  //     TokenSymbols.USDT,
  //     'Tether USD',
  //     6,
  //     SupportedChainId.ZKSYNC_MAINNET,
  //     '0x',
  //     {
  //       method: FetchPriceMethod.COINGECKO,
  //       payload: {
  //         id: 'tether',
  //       },
  //     },
  //     SwapGroupSymbol.USD,
  //     'USDT'
  //   ),
  //   [TokenSymbols.WOM]: new Token(
  //     TokenSymbols.WOM,
  //     'WOM',
  //     18,
  //     SupportedChainId.ZKSYNC_MAINNET,
  //     '0x',
  //     {
  //       method: FetchPriceMethod.COINGECKO,
  //       payload: {
  //         id: 'wombat-exchange',
  //       },
  //     },
  //     SwapGroupSymbol.WOM
  //   ),
  // },
  // [SupportedChainId.ZKSYNC_TESTNET]: {
  //   [TokenSymbols.USDC]: new Token(
  //     TokenSymbols.USDC,
  //     'USD Coin',
  //     18,
  //     SupportedChainId.ZKSYNC_TESTNET,
  //     '0xd80D6A9943a1AB96F47Dcf8F02Faf1037ca94Fd0',
  //     {
  //       method: FetchPriceMethod.PYTH,
  //       payload: {
  //         pythPriceFeedId: USDC_PYTH_ID,
  //       },
  //     },
  //     SwapGroupSymbol.USD,
  //     'USDC'
  //   ),
  //   [TokenSymbols.USDT]: new Token(
  //     TokenSymbols.USDT,
  //     'Tether USD',
  //     6,
  //     SupportedChainId.ZKSYNC_TESTNET,
  //     '0xf0Af6A1C927A79Cd5CC5A2bA505bD58841C61e83',
  //     {
  //       method: FetchPriceMethod.PYTH,
  //       payload: {
  //         pythPriceFeedId: USDT_PYTH_ID,
  //       },
  //     },
  //     SwapGroupSymbol.USD,
  //     'USDT'
  //   ),
  // },
}

export const pythIdTokenSymbolInSupportedChainIdsMapping: {
  [chainId in SupportedChainId]: { [id in TokenSymbol]: string }
} = {} as {
  [chainId in SupportedChainId]: { [id in TokenSymbol]: string }
}

// init pythIdTokenSymbolInSupportedChainIdsMapping
Object.entries(TOKENS).map(([chainIdStr, tokenInstancesInTheChain]) => {
  const chainId = chainIdStr as unknown as SupportedChainId
  pythIdTokenSymbolInSupportedChainIdsMapping[chainId] = {} as { [id in TokenSymbol]: string }
  Object.entries(tokenInstancesInTheChain).map(([tokenSymbol, token]) => {
    if (token.fetchPriceData && token.fetchPriceData.method === FetchPriceMethod.PYTH) {
      pythIdTokenSymbolInSupportedChainIdsMapping[chainId][tokenSymbol as TokenSymbol] =
        token.fetchPriceData.payload.pythPriceFeedId
    }
  })
})

export const tokenAddressTokenMap: {
  [chainId in SupportedChainId]?: { [id in string]: Token }
} = Object.entries(TOKENS).reduce((acc, [chainIdStr, tokensMap]) => {
  const chainId = chainIdStr as unknown as SupportedChainId
  const assetList = Object.values(tokensMap).reduce(
    (acc, token) => ({ ...acc, [token.address.toLowerCase()]: token }),
    {}
  )
  return {
    ...acc,
    [chainId]: assetList,
  }
}, {})

export const getSupportedChainIdByTokenAddress = (
  tokenAddress: string,
  wormholeChainId?: WormholeChainId
) => {
  for (const [supportedChainId, tokensMap] of Object.entries(TOKENS)) {
    /** skip those chain which does not match with the wormholechainId */
    if (
      supportedChainIdToWormholeChainIdMap[supportedChainId as unknown as SupportedChainId] !==
      wormholeChainId
    )
      continue
    const tokens = Object.values(tokensMap)
    for (const token of tokens) {
      if (token.address.toLowerCase() === tokenAddress.toLowerCase()) return token.chainId
    }
  }
}

export const NATIVE_WRAPPED_TOKEN_IN_CHAIN: {
  [chainId in SupportedChainId]: TokenSymbol
} = {
  [SupportedChainId.ARBITRUM_ONE]: TokenSymbols.WETH,
  [SupportedChainId.BSC_TESTNET]: TokenSymbols.WBNB,
  [SupportedChainId.BSC_MAINNET]: TokenSymbols.WBNB,
  [SupportedChainId.FUJI]: HardcodedTokenSymbol.AVAX,
  [SupportedChainId.ETHEREUM_MAINNET]: TokenSymbols.WETH,
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: TokenSymbols.WETH,
  [SupportedChainId.SCROLL_MAINNET]: TokenSymbols.WETH,
  [SupportedChainId.SKALE_TESTNET]: HardcodedTokenSymbol.SKL,
  [SupportedChainId.OP_MAINNET]: TokenSymbols.WETH,
  /** @todo should replace it with TokenSymbols.WMATIC */
  [SupportedChainId.POLYGON_MAINNET]: HardcodedTokenSymbol.WMATIC,
  [SupportedChainId.AVAX]: TokenSymbols.WAVAX,
  [SupportedChainId.BASE]: TokenSymbols.WETH,
  /**@todo uncomment it when needed */
  // [SupportedChainId.ZKSYNC_MAINNET]: TokenSymbols.WETH,
  // [SupportedChainId.ZKSYNC_TESTNET]: TokenSymbols.WETH,
}

export const DEFAULT_SWAP_TOKEN_IN_CHAIN: {
  [chainId in SupportedChainId]: {
    fromTokenSymbol: TokenSymbol
    toTokenSymbol: TokenSymbol
  }
} = {
  [SupportedChainId.ARBITRUM_ONE]: {
    fromTokenSymbol: TokenSymbols.USDT,
    toTokenSymbol: TokenSymbols.USDC,
  },
  [SupportedChainId.BSC_TESTNET]: {
    fromTokenSymbol: TokenSymbols.USDT,
    toTokenSymbol: TokenSymbols.USDC,
  },
  [SupportedChainId.BSC_MAINNET]: {
    fromTokenSymbol: TokenSymbols.USDT,
    toTokenSymbol: TokenSymbols.USDC,
  },
  [SupportedChainId.FUJI]: {
    fromTokenSymbol: TokenSymbols.BUSD,
    toTokenSymbol: TokenSymbols.vUSDC,
  },
  [SupportedChainId.ETHEREUM_MAINNET]: {
    fromTokenSymbol: TokenSymbols.WETH,
    toTokenSymbol: TokenSymbols.frxETH,
  },
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: {
    fromTokenSymbol: TokenSymbols.USDT,
    toTokenSymbol: TokenSymbols.USDC,
  },
  [SupportedChainId.SCROLL_MAINNET]: {
    fromTokenSymbol: TokenSymbols.USDT,
    toTokenSymbol: TokenSymbols.USDC,
  },
  [SupportedChainId.SKALE_TESTNET]: {
    fromTokenSymbol: TokenSymbols.USDT,
    toTokenSymbol: TokenSymbols.USDC,
  },
  [SupportedChainId.OP_MAINNET]: {
    fromTokenSymbol: TokenSymbols.USDT,
    toTokenSymbol: TokenSymbols.USDC,
  },
  [SupportedChainId.AVAX]: {
    /** USDT's symbol is USDt on avalanche */
    fromTokenSymbol: TokenSymbols.USDT,
    toTokenSymbol: TokenSymbols.USDC,
  },
  [SupportedChainId.POLYGON_MAINNET]: {
    fromTokenSymbol: TokenSymbols.USDT,
    toTokenSymbol: TokenSymbols.USDC,
  },
  [SupportedChainId.BASE]: {
    fromTokenSymbol: TokenSymbols.USDC,
    toTokenSymbol: TokenSymbols.USDbC,
  },
  /** @todo unhide it when needed  */
  // [SupportedChainId.ZKSYNC_MAINNET]: {
  //   fromTokenSymbol: TokenSymbols.USDT,
  //   toTokenSymbol: TokenSymbols.USDC,
  // },
  // [SupportedChainId.ZKSYNC_TESTNET]: {
  //   fromTokenSymbol: TokenSymbols.USDT,
  //   toTokenSymbol: TokenSymbols.USDC,
  // },
}
